// src/theme.ts
import {
  DEFAULT_THEME,
  colorsTuple,
  createTheme,
  mergeMantineTheme,
  rem
} from '@mantine/core';

const _theme = createTheme({
  autoContrast: true,
  breakpoints: {
    lg: '64em',
    md: '52.125em',
    sm: '48em',
    xl: '90em',
    xs: '30em'
  },
  colors: {
    dark: colorsTuple('#25262B')
  },
  components: {
    Button: {
      styles: {
        inner: {
          gap: '4px'
        }
      }
    },
    Drawer: {
      styles: {
        content: { backgroundColor: '#dcdbdb' }
      }
    },
    Input: {
      styles: {
        label: {
          color: '#1d2424'
        },
        root: {
          gap: '10px'
        }
      }
    },
    Text: {
      styles: {
        root: {
          color: '#1d2424'
        }
      }
    }
  },
  fontFamily: 'Helvetica',
  fontSizes: {
    lg: rem(16),
    md: rem(14),
    sm: rem(11),
    xl: rem(24),
    xs: rem(10)
  },
  lineHeights: {
    lg: rem(20),
    md: rem(14),
    sm: rem(11),
    xl: rem(32),
    xs: rem(10)
  },
  primaryColor: 'dark'

  // ... other theme override properties
});

export const theme = mergeMantineTheme(DEFAULT_THEME, _theme);
